import { useEffect } from "react";

const usePreventBackNavigation = () => {
  useEffect(() => {
    const preventBackNavigation = () => {
      window.history.pushState(null, "", window.location.pathname);
    };

    // Push a new state to the history stack to prevent going back
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", preventBackNavigation);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", preventBackNavigation);
    };
  }, []);
};

export default usePreventBackNavigation;
