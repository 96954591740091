import React, { useEffect, useRef, useState } from "react";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import moment from "moment";

interface MyDatePickerProps {
  minDate: Date;
  checkInDate: Date | null;
  setCheckInDate: (date: Date | null) => void;
  isDisableCheck: boolean;
  customFormatter: boolean;
}

function convertTZ(date: string, tzString: string) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
}

export const MyDatePicker: React.FC<MyDatePickerProps> = ({
  minDate,
  checkInDate,
  setCheckInDate,
  isDisableCheck,
  customFormatter,
}) => {
  const datePickerWrapperRef = useRef<HTMLDivElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const datePickerRef = useRef<any>(null);
  const [includeTime, setIncludeTime] = useState(customFormatter); // condition to switch format

  // useEffect(() => {
  //   if (datePickerWrapperRef.current) {
  //     const inputElement = datePickerWrapperRef.current.querySelector("input");
  //     if (inputElement) {
  //       inputElement.readOnly = true; // Disable manual input

  //       // Add a keydown event listener to prevent arrow key navigation
  //       const handleKeyDown = (e: KeyboardEvent) => {
  //         if (["ArrowUp", "ArrowDown"].includes(e.key)) {
  //           e.preventDefault(); // Prevent the default behavior for arrow keys
  //         }
  //       };

  //       inputElement.addEventListener("keydown", handleKeyDown);

  //       // Clean up the event listener on component unmount
  //       return () => {
  //         inputElement.removeEventListener("keydown", handleKeyDown);
  //       };
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const inputElement = datePickerRef.current?.element?.querySelector("input");
    if (inputElement) {
      // Disable arrow keys
      inputElement.addEventListener("keydown", (event: KeyboardEvent) => {
        // if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        //   event.preventDefault();
        // }
        event.preventDefault();
        // Prevent number key events (0-9)
        // if (event.key >= "0" && event.key <= "9") {
        //   event.preventDefault();
        // }
      });

      // Disable mouse wheel
      inputElement.addEventListener("wheel", (event: WheelEvent) => {
        event.preventDefault();
      });

      // Clean up event listeners
      return () => {
        inputElement.removeEventListener("keydown", () => {});
        inputElement.removeEventListener("wheel", () => {});
      };
    }
  }, []);

  // const handleDateChange = (e: DatePickerChangeEvent) => {
  //   const value = new Date(e.value?.toString().split("GMT")[0] + " UTC")
  //     .toISOString()
  //     .split(".")[0];
  //   setSelectedDate(e.value);
  //   const finalBookingDateStr = `${formatMomentTime(
  //     value,
  //     "YYYY-MM-DD"
  //   )}T${moment(new Date()).format("HH:mm:ss")}.000Z`;
  //   setCheckInDate(e.value);
  //   // if (datePickerRef.current) {
  //   //   setTimeout(() => {
  //   //     if (datePickerRef.current && datePickerRef.current.element) {
  //   //       datePickerRef.current.element.setAttribute("readonly", "readonly"); // Prevent further focus
  //   //       datePickerRef.current.element.blur(); // Remove focus
  //   //     }
  //   //   }, 0);
  //   // }
  // };

  const handleDateChange = (e: DatePickerChangeEvent) => {
    if (
      e.nativeEvent.code === "ArrowUp" ||
      e.nativeEvent.code === "ArrowDown" ||
      e.nativeEvent.type === "input" ||
      e.nativeEvent.data === "0"
    ) {
      e.syntheticEvent.preventDefault();
    } else {
      const value = new Date(e.value?.toString().split("GMT")[0] + " UTC")
        .toISOString()
        .split(".")[0];
      setSelectedDate(e.value);
      setCheckInDate(e.value);
    }
  };

  const formatMomentTime = (dateStr: string, dateTimeFormat: string) => {
    const date = dateStr.split("-");
    const joinedDate = `${date[0]}-${date[1]}-${date[2]}`;
    const finalDateStr = joinedDate.replace("Z", "");
    const parsedDate = convertTZ(finalDateStr, "Asia/Kolkata");
    return moment(parsedDate).format(dateTimeFormat);
  };

  // Function to dynamically set the date format
  const getCustomFormatterDate = () => {
    return includeTime ? "dd-MMM-yyyy HH:mm" : "EEE, dd/MM/yyyy";
  };

  return (
    <div ref={datePickerWrapperRef}>
      <DatePicker
        disabled={isDisableCheck}
        value={checkInDate}
        onChange={handleDateChange}
        min={minDate}
        placeholder={"EEE, DD/MM/YYYY"}
        format={getCustomFormatterDate()}
        className="personal-fields no-focus"
        name="bookingDatetime"
        ref={datePickerRef}
        onWheel={(e) => e.preventDefault()}
      />
    </div>
  );
};
