import React, { useEffect, useState } from "react";

interface Category {
  id: number;
  name: string;
}

const Categories = ({
  categories,
  onSelectCategory,
}: {
  categories: Category[];
  onSelectCategory: (id: number) => void;
}) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );

  useEffect(() => {
    setSelectedCategoryId(categories[0].id);
    onSelectCategory(categories[0].id);
  }, []);

  const handleSelectCategory = (id: number) => {
    setSelectedCategoryId(id);
    onSelectCategory(id);
  };

  return (
    <div className="categories">
      {categories.map((category) => (
        <div
          key={category.id}
          className={`category-card ${
            selectedCategoryId === category.id ? "selected" : ""
          }`}
          onClick={() => handleSelectCategory(category.id)}
        >
          {category.name}
        </div>
      ))}
    </div>
  );
};

export default Categories;
