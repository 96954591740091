import React, { useEffect, useRef, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import "../src/RoomBooking.scss";
import "../src/Common.scss";
import { Popup } from "@progress/kendo-react-popup";
import { Button } from "@progress/kendo-react-buttons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { commonBaseUrl, commonImageUrl } from "./services/CommonUtils";

interface orderList {
  Id: number;
  UserId: number;
  OrderDate: string;
  OrderStatus: string;
  statusTextColor: string;
  statusColor: string;
  foodNumber: string;
  TotalAmount: number;
  complementaryAmount: number;
  foodOrderDetails: orderDetailsList[];
}

interface orderDetailsList {
  id: number;
  TypeId: string;
  foodOrderHeaderId: number;
  subCategoryItemId: number;
  SubCategoryItemName: string;
  SubCategoryItemDescription: string;
  SubCategoryItemImage: string;
  quantity: number;
  orderType: number;
  price: number;
  isAvailable: boolean;
}

export const MyOrders = () => {
  const baseURL = commonBaseUrl();
  const imageURL = commonImageUrl();
  const navigate = useNavigate();

  const [orderDetailsData, setOrderDetailsData] = useState<orderList[]>([]);
  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const anchorRef = useRef(null);
  const popupRef = useRef(null);
  const hasFetched = useRef(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [visible, setVisible] = useState<boolean>(false);

  // Convert Date Server to MMM DD YYYY
  const convertDate = (dateString: any) => {
    const date = new Date(dateString);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    var savedPreference = localStorage.getItem("name");
    if (savedPreference !== "" && savedPreference !== null) {
      setLoggedIn(true);
    }
    getProfile();
    getBookingList();
  });

  // Close popup if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const path = event.composedPath ? event.composedPath() : event.path;
      if (
        !path.includes(popupRef.current) &&
        !path.includes(anchorRef.current)
      ) {
        setShowPopup(false);
      }
    };
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const getProfile = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/DocumentUploads?$filter=refno eq ${localStorage.getItem(
          "id"
        )} and contains(reftype, 'Profile')`
      );
      if (response.data.value.length !== 0) {
        if (
          response.data.value[response.data.value.length - 1].Name ===
          "A6CC0B0F4DP2012E"
        ) {
          setProfileImage("");
        } else {
          const fileURL: any =
            imageURL +
            response.data.value[response.data.value.length - 1].ImagePath;
          setProfileImage(fileURL);
        }
      } else {
      }
    } catch (err) {
      alert(err);
    }
  };
  const [expanded, setExpanded] = useState("");

  // Initial load API's
  const getBookingList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}GetAllFoodOrderHeaderAndDetailByUserId?userId=${localStorage.getItem(
          "id"
        )}`
      );
      const orders: any[] = [];
      const responseData = response.data;
      if (responseData.length > 0) {
        for (let s = 0; s < responseData.length; s++) {
          const orderDetails: any[] = [];
          const foodOrders = responseData[s].foodOrderDetails;
          for (let d = 0; d < foodOrders.length; d++) {
            const orderDetailsData = {
              id: foodOrders[d].id,
              TypeId: foodOrders[d].orderType,
              foodOrderHeaderId: foodOrders[d].foodOrderHeaderId,
              subCategoryItemId: foodOrders[d].subCategoryItemId,
              SubCategoryItemName: "",
              SubCategoryItemDescription: "",
              SubCategoryItemImage: "",
              quantity: Number(foodOrders[d].quantity),
              orderType:
                Number(foodOrders[d].orderType) === 1 ? "Veg" : "Non-Veg",
              complimentary: 0,
              price: Number(foodOrders[d].price),
              isAvailable: foodOrders[d].isAvailable,
            };
            orderDetails.push(orderDetailsData);
          }
          const orderData = {
            Id: Number(responseData[s].id),
            UserId: Number(responseData[s].userId),
            OrderDate: convertDate(responseData[s].orderDate),
            OrderStatus: responseData[s].orderStatus,
            foodNumber: responseData[s].foodNumber,
            TotalAmount: Number(responseData[s].totalAmount),
            complementaryAmount: Number(responseData[s].complementaryAmount),
            foodOrderDetails: orderDetails,
          };
          orders.push(orderData);
        }
        setExpanded(orders[0].Id);
        getSubCategoryList(orders);
      } else {
        setLoading(false);
      }
    } catch (err) {
      alert(err);
    }
  };

  const getSubCategoryList = async (responseData: any) => {
    try {
      const updatedSub = await Promise.all(
        responseData.map(async (item: orderList) => {
          for (const sub of item.foodOrderDetails) {
            try {
              const subResponse = await axios.get(
                `${baseURL}odata/SubCategorys(${sub.subCategoryItemId})`
              );
              if (
                subResponse.data &&
                subResponse.data.value &&
                subResponse.data.value.length > 0
              ) {
                sub.SubCategoryItemName = subResponse.data.value[0].Name;
                sub.SubCategoryItemDescription =
                  subResponse.data.value[0].Description;
              } else {
                console.error(
                  `Unexpected response format for SubCategory ID: ${sub.subCategoryItemId}`
                );
              }
            } catch (subErr) {
              console.error(
                `Error fetching subcategory with ID ${sub.subCategoryItemId}:`,
                subErr
              );
            }
          }
          return item; // Return the updated item after processing all its subcategories
        })
      );

      setOrderDetailsData(updatedSub); // Set the updated data
      setLoading(false); // Stop loading
    } catch (err) {
      alert("An error occurred while fetching subcategories");
    }
  };

  // Login status
  const handleLoginLogout = () => {
    setLogoutDialog(true);
    setShowPopup(false); // Close the popup after clicking
  };

  // Popup visibility
  const handleProfileClick = () => {
    setShowPopup(!showPopup);
  };

  // menu flow
  const navigateMyProfile = () => {
    navigate("/myprofile");
  };

  const logoutHandler = (isLogout: any) => {
    if (isLogout === true) {
      removeUserDetails();
    }
    setLogoutDialog(false);
  };

  const removeUserDetails = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("customer_type");
    localStorage.removeItem("company_name");
    localStorage.removeItem("name");
    localStorage.removeItem("mobile");
    localStorage.removeItem("email");
    localStorage.removeItem("address");
    localStorage.removeItem("address2");
    localStorage.removeItem("country");
    localStorage.removeItem("state");
    localStorage.removeItem("city");
    localStorage.removeItem("last_name");
    localStorage.removeItem("pin_code");
    localStorage.removeItem("kyc_document_id");
    localStorage.removeItem("gst_number");
    localStorage.removeItem("created");
    localStorage.removeItem("token");
    localStorage.removeItem("customerID");
    navigate("/");
  };

  const navigateNextScreen = () => {
    navigate("/bookings");
  };
  const checkingMethod = async (data: any) => {
    cancelSet(false);
    console.log("Cancel order data set was", data);
    try {
      const response = await axios.get(
        `${baseURL}odata/FoodOrderHeaders/${data.Id}`
      );
      console.log("Response -->", response.data.value[0]);
      var Status = response.data.value[0].OrderStatus;
      if (Status === 1) {
        // console.log("yes",data.Id);
        CancelOrderApi(data.Id);
      } else if (Status === 2) {
        setAlertContent("Already Your Order was taken by Cafeteria");
        setVisible(true);
      } else if (Status === 4) {
        setAlertContent("Your Order was Completed");
        setVisible(true);
      } else {
        setAlertContent("Already Your Order was cancelled by Cafeteria");
        setVisible(true);
      }
    } catch (error) {}
  };

  // New Api
  const CancelOrderApi = async (id: any) => {
    try {
      const response = await axios.post(
        `${baseURL}odata/CancelFoodOrderStatus?orderId=${id}`
      );
      console.log("Fetched Data", response.data);
      packageUpdateApi(id);
      // window.location.reload();
    } catch (err: any) {
      console.log("error", err);
      window.location.reload();
    }
  };

  const packageUpdateApi = async (id: any) => {
    try {
      const response = await axios.post(
        `${baseURL}odata/UpdateUtilizedQuantitiesForCanceledOrder?orderId=${id}`
      );
      console.log("Package cancelled Data -->", response.data);
      window.location.reload();
    } catch (err: any) {
      console.log("error 11", err.response.data.message);
      window.location.reload();
    }
  };
  function updateDialog() {
    window.location.reload();
  }

  const [cancel, cancelSet] = useState(false);
  const [cancelData, cancelDataSet] = useState({});

  return (
    <div className="booking-container">
      {cancel && (
        <Dialog title={"Message"} onClose={() => cancelSet(false)}>
          <p style={{ margin: "25px", textAlign: "center", height: "150px" }}>
            Are you sure want to cancel your order ?
            {/* Booking Details Updated Successfully */}
          </p>
          <DialogActionsBar>
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                checkingMethod(cancelData);
              }}
            >
              Ok
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {visible && (
        <Dialog title={"Message"} onClose={updateDialog}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            {alertContent}
            {/* Booking Details Updated Successfully */}
          </p>
          <DialogActionsBar>
            <Button type="button" onClick={updateDialog}>
              Ok
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      <AppBar className="header-row">
        <AppBarSection>
          <img
            src={require("./assets/images/logo.png")}
            alt="Logo"
            style={{ height: "40px", marginRight: "20px" }}
          />
        </AppBarSection>
        <AppBarSection className="menu-alignment">
          <Label className="top-view top-selected">
            <Link to="/">Book a Space</Link>
          </Label>
        </AppBarSection>
        <AppBarSpacer style={{ flexGrow: 1 }} />
        <AppBarSection className="top-view">
          {profileImage === "" ? (
            <img
              ref={anchorRef}
              src={require("./assets/images/profile.png")}
              alt="Profile"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileClick}
            />
          ) : (
            <img
              ref={anchorRef}
              src={profileImage}
              alt="Profile"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileClick}
            />
          )}
          <Popup
            anchor={anchorRef.current}
            collision={{
              horizontal: "fit",
              vertical: "flip",
            }}
            show={showPopup}
            popupClass={"popup-content"}
            animate={false}
          >
            <div ref={popupRef} style={{ padding: "10px" }}>
              {loggedIn && (
                <div>
                  <Label
                    style={{ color: "#42B677" }}
                  >{`Welcome ${localStorage.getItem("name")}`}</Label>
                </div>
              )}
              {loggedIn && (
                <div>
                  <Button
                    fillMode="link"
                    className="popup-button-block"
                    onClick={navigateNextScreen}
                  >
                    My Bookings
                  </Button>
                  <Button
                    fillMode="link"
                    className="popup-button-block popup-button-color"
                  >
                    My Orders
                  </Button>
                  <Button
                    fillMode="link"
                    className="popup-button-block"
                    onClick={navigateMyProfile}
                  >
                    My Profile
                  </Button>
                </div>
              )}
              <Button fillMode="link" onClick={handleLoginLogout}>
                {loggedIn ? "Logout" : "Login"}
              </Button>
            </div>
          </Popup>
        </AppBarSection>
      </AppBar>
      <h2 className="bookings-header">Order History</h2>
      {loading && (
        <div className="loading-screen">
          <Loader size="large" type={"infinite-spinner"} />
        </div>
      )}
      {!loading && (
        <div>
          {orderDetailsData.length == 0 ? (
            <div className="center-screen">No orders data found</div>
          ) : (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="form-content-shedular-accordian">
                <div style={{ padding: "20px" }}>
                  {/* <div className="booking-list"> */}
                  {orderDetailsData.map((bookingDataList: any) => (
                    <div className="order-listview-container">
                      <div className="listview-imageContainer"></div>
                      <div className="custom-details-container row">
                        {/* Main Heading */}
                        <div className="custom-details-body">
                          {/* <div className="custom-detail-item col-2">
                            <Label className="custom-label">Cafeteria:</Label>
                            <Label className="custom-value">
                              WorkFromHaven
                            </Label>
                          </div> */}

                          <div className="custom-detail-item col-2">
                            <Label className="custom-label">Order ID:</Label>
                            <Label className="custom-value">
                              {bookingDataList.foodNumber}
                            </Label>
                          </div>

                          <div className="custom-detail-item col-2">
                            <Label className="custom-label">
                              Complimentary:
                            </Label>
                            <Label className="custom-value">
                              {bookingDataList.complementaryAmount === 0
                                ? "-"
                                : `₹ ${bookingDataList.complementaryAmount}`}
                            </Label>
                          </div>

                          <div className="custom-detail-item col-2">
                            <Label className="custom-label">
                              Total amount:
                            </Label>
                            <Label className="custom-value">
                              ₹ {bookingDataList.TotalAmount}
                            </Label>
                          </div>

                          <div className="custom-detail-item col-2">
                            <Label className="custom-label">Order Date:</Label>
                            <Label className="custom-value">{`${bookingDataList.OrderDate}`}</Label>
                          </div>
                          {bookingDataList.OrderStatus === 1 && (
                            <div className="custom-detail-item col-2">
                              <Button
                                className="button-style-cancel"
                                onClick={() => {
                                  console.log(
                                    "Clicked item was -->",
                                    bookingDataList
                                  );
                                  cancelDataSet(bookingDataList);
                                  cancelSet(true);
                                  // cancelOrderAlert(bookingDataList);
                                }}
                              >
                                Cancel Order
                              </Button>
                            </div>
                          )}
                          {bookingDataList.OrderStatus === 2 && (
                            <div className="custom-detail-item col-2">
                              <div className="button-style-outline-inprogress">
                                Inprogress
                              </div>
                            </div>
                          )}
                          {bookingDataList.OrderStatus === 3 && (
                            <div className="custom-detail-item col-2">
                              <Button className="button-style-outline-Canel">
                                Cancelled
                              </Button>
                            </div>
                          )}
                          {bookingDataList.OrderStatus === 4 && (
                            <div className="custom-detail-item col-2">
                              <Button className="button-style-end">
                                Completed
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className="custom-details-body">
                          <div className="custom-detail-item col-2">
                            <Label className="custom-sub-label">
                              Order details:
                            </Label>
                          </div>
                        </div>
                        {/* Sub Heading */}
                        <div className="custom-details-body">
                          <div className="custom-detail-item col-2"></div>

                          <div className="custom-detail-item col-2"></div>

                          <div className="custom-detail-item col-2"></div>
                        </div>
                        {/* Sub Value */}
                        {bookingDataList.foodOrderDetails.map(
                          (foodDetailsList: any) => (
                            <div className="custom-food-details-body">
                              <div className="custom-detail-item col-2">
                                <Label className="custom-label">
                                  Food Type:
                                </Label>
                                <Label className="custom-value">
                                  <img
                                    src={require(`./assets/images/${
                                      foodDetailsList.orderType === "Veg"
                                        ? `veg`
                                        : `non`
                                    }.png`)}
                                    alt="Lounge Space"
                                    className="food-type-logo"
                                  />
                                  {foodDetailsList.orderType}
                                </Label>
                              </div>

                              <div className="custom-detail-item col-2">
                                <Label className="custom-label">
                                  Food Name:
                                </Label>
                                <Label className="custom-value">
                                  {foodDetailsList.SubCategoryItemName}
                                </Label>
                              </div>

                              <div className="custom-detail-item col-2">
                                <Label className="custom-label">
                                  Quantity:
                                </Label>
                                <Label className="custom-value">
                                  {foodDetailsList.quantity}
                                </Label>
                              </div>
                              <div className="custom-detail-item col-2">
                                <Label className="custom-label"></Label>
                                <Label className="custom-value"></Label>
                              </div>
                              <div className="custom-detail-item col-2">
                                <Label className="custom-label"></Label>
                                <Label className="custom-value"></Label>
                              </div>
                              {/* <div className="custom-detail-item col-2">
                                <Label className="custom-value">
                                  {foodDetailsList.price === 0
                                    ? `${"₹ -"} ${
                                        foodDetailsList.complimentary *
                                          foodDetailsList.quantity -
                                        foodDetailsList.price *
                                          foodDetailsList.quantity
                                      }`
                                    : `${"₹ -"} ${
                                        foodDetailsList.complimentary *
                                          foodDetailsList.quantity -
                                        foodDetailsList.price
                                      }`}
                                </Label>
                              </div>

                              <div className="custom-detail-item col-2">
                                <Label className="custom-value">
                                  {foodDetailsList.price === 0
                                    ? `${"₹ "} ${
                                        foodDetailsList.complimentary *
                                        foodDetailsList.quantity
                                      }`
                                    : `${"₹ "} ${
                                        foodDetailsList.price *
                                        foodDetailsList.quantity
                                      }`}
                                </Label>
                              </div> */}
                            </div>
                          )
                        )}
                        {orderDetailsData.length === 0 && (
                          <div className="booking-listview-no-data">
                            No orders data found
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {/* </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/* Logout dialog */}
      {logoutDialog && (
        <Dialog
          onClose={() => setLogoutDialog(false)}
          className="dialog-container"
        >
          <div className="dialog-content">
            <Label className="dialog-heading">Alert Dialog</Label>
            <Label>Are you sure you want to logout?</Label>
            <div className="login-button-content">
              <Button
                className="login-button-dialog"
                onClick={(e) => logoutHandler(true)}
              >
                Yes
              </Button>
              <Button
                className="login-button-dialog"
                onClick={(e) => logoutHandler(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};
