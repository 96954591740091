import React, { useEffect, useRef, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Card,
} from "@progress/kendo-react-layout";
import "../src/RoomBooking.scss";
import "../src/Common.scss";
import { Popup } from "@progress/kendo-react-popup";
import { Button } from "@progress/kendo-react-buttons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@progress/kendo-react-common";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TextArea } from "@progress/kendo-react-inputs";
import axios from "axios";
import { Loader } from "@progress/kendo-react-indicators";
import fileDownload from "js-file-download";
import { commonBaseUrl, commonImageUrl } from "./services/CommonUtils";

export const BookingDetail = () => {
  const baseURL = commonBaseUrl();
  const imageURL = commonImageUrl();

  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const bookingDetail = location.state?.bookingDetail;
  const [loggedIn, setLoggedIn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const anchorRef = useRef(null);
  const popupRef = useRef(null);
  const hasFetched = useRef(false);
  const navigate = useNavigate();

  const [invoicePath, setInvoicePath] = useState("");
  const [invoiceName, setInvoiceName] = useState("");
  const [invoiceType, setInvoiceType] = useState("");

  const navigateBack = () => {
    navigate("/bookings");
  };

  // Convert Date Server to MMM DD YYYY
  const convertDate = (dateString: any) => {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Define month names to convert the numeric month to abbreviated form
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the parts of the date you need
    const year = date.getFullYear();
    const month = months[date.getMonth()]; // Get the month as a string
    const day = date.getDate();

    // Format it in the desired format "Sep 27 2024"
    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    var savedPreference = localStorage.getItem("name");
    if (savedPreference !== "" && savedPreference !== null) {
      setLoggedIn(true);
    }
    getProfile();
    getInvoiceAPI();
  });

  // Close popup if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the click is outside both the popup and the anchor (profile image)
      const path = event.composedPath ? event.composedPath() : event.path;
      if (
        !path.includes(popupRef.current) &&
        !path.includes(anchorRef.current)
      ) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when component unmounts or showPopup changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const getProfile = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/DocumentUploads?$filter=refno eq ${localStorage.getItem(
          "id"
        )} and contains(reftype, 'Profile')`
      );
      if (response.data.value.length !== 0) {
        if (
          response.data.value[response.data.value.length - 1].Name ===
          "A6CC0B0F4DP2012E"
        ) {
          setProfileImage("");
        } else {
          const fileURL: any =
            imageURL +
            response.data.value[response.data.value.length - 1].ImagePath;
          setProfileImage(fileURL);
        }
      } else {
      }
    } catch (err) {
      alert(err);
    }
  };

  const getInvoiceAPI = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/DocumentUploads?$filter=refno eq ${bookingDetail.Id} and contains(reftype, 'Invoices')`,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        if (response.data.value.length > 0) {
          const fullUrl = `${imageURL}${response.data.value[0].ImagePath}`;
          setInvoicePath(fullUrl);
          setInvoiceName(response.data.value[0].Name);
          setInvoiceType(response.data.value[0].Type);
        }
        setLoading(false);
      } else {
        setLoading(false);
        setAlertContent("Server not rechable. Please try again later!");
      }
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  // Login status
  const handleLoginLogout = () => {
    setShowPopup(false); // Close the popup after clicking
  };

  // Popup visibility
  const handleProfileClick = () => {
    setShowPopup(!showPopup);
  };

  const navigateMyProfile = () => {
    navigate("/myprofile");
  };

  const navigateNextScreen = () => {
    navigate("/bookings");
  };

  const [visibleDialog, setVisibleDialog] = React.useState<boolean>(false);
  const [notesErr, setNotesErr] = useState("");
  const [isCheckNotesErr, setIsCheckNotesErr] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [alertContent, setAlertContent] = React.useState<String>("");
  const [notes, setNotes] = React.useState("");
  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };

  const cancelAPIRequest = async () => {
    if (notes.trim() === "") {
      setNotesErr("Notes is required");
      setIsCheckNotesErr(true);
    } else {
      setNotesErr("");
      setVisibleDialog(false);
      setLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}Odata/SendCancelBookingEmail?email=${localStorage.getItem(
            "email"
          )}&bookingId=${bookingDetail.Id}&notes=${notes}`,
          {
            headers: header,
          }
        );
        if (response.status === 200) {
          setLoading(false);
          setNotes("");
          setVisible(true);
          setAlertContent(response.data.message);
        } else {
          setLoading(false);
          setVisible(true);
          setAlertContent("Server not rechable. Please try again later!");
        }
      } catch (err) {
        setLoading(false);
        alert(err);
      }
    }

    setLoading(false);
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleViewInvoice = () => {
    window.open(invoicePath, "_blank");
  };

  const handleDownloadInvoice = () => {
    setLoading(true);
    axios
      .get(invoicePath, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, invoiceName + "." + invoiceType);
        setLoading(false);
      });
  };

  const navigateMyOrders = () => {
    navigate("/myorders");
  };

  return (
    <div className="booking-container">
      <AppBar className="header-row">
        <AppBarSection>
          <img
            src={require("./assets/images/logo.png")}
            alt="Logo"
            style={{ height: "40px", marginRight: "20px" }}
          />
        </AppBarSection>
        <AppBarSection className="menu-alignment">
          <Label className="top-view top-selected">
            <Link to="/">Book a Space</Link>
          </Label>
        </AppBarSection>
        <AppBarSpacer style={{ flexGrow: 1 }} />
        <AppBarSection className="top-view">
          {profileImage === "" ? (
            <img
              ref={anchorRef}
              src={require("./assets/images/profile.png")}
              alt="Profile"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileClick}
            />
          ) : (
            <img
              ref={anchorRef}
              src={profileImage}
              alt="Profile"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileClick}
            />
          )}

          <Popup
            anchor={anchorRef.current}
            collision={{
              horizontal: "fit",
              vertical: "flip",
            }}
            show={showPopup}
            popupClass={"popup-content"}
            animate={false}
          >
            <div ref={popupRef} style={{ padding: "10px" }}>
              {loggedIn && (
                <div>
                  <Label
                    style={{ color: "#42B677" }}
                  >{`Welcome ${localStorage.getItem("name")}`}</Label>
                </div>
              )}
              {loggedIn && (
                <div>
                  <Button
                    fillMode="link"
                    className="popup-button-block popup-button-color"
                    onClick={navigateNextScreen}
                  >
                    My Bookings
                  </Button>
                  <Button
                    fillMode="link"
                    className="popup-button-block"
                    onClick={navigateMyOrders}
                  >
                    My Orders
                  </Button>
                  <Button
                    fillMode="link"
                    className="popup-button-block"
                    onClick={navigateMyProfile}
                  >
                    My Profile
                  </Button>
                </div>
              )}
              <Button fillMode="link" onClick={handleLoginLogout}>
                {loggedIn ? "Logout" : "Login"}
              </Button>
            </div>
          </Popup>
        </AppBarSection>
      </AppBar>
      <h2 className="bookings-header">My Bookings</h2>
      {loading && (
        <div className="loading-screen">
          <Loader size="large" type={"infinite-spinner"} />
        </div>
      )}
      {!loading && (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="mybooking-details-card">
            <div className="backbutton">
              <Button fillMode="link" onClick={navigateBack}>
                <Icon name="arrow-chevron-left" className="kendo-back-icon" />
                <span className="back-text">BACK</span>
              </Button>
            </div>
            <div className="booking-history-container">
              {/* Left container */}
              <div className="booking-left-section-removed">
                <div className="booking-details-header">
                  <div className="separate-booking-container"></div>
                  <img
                    src={`${imageURL}${bookingDetail.SpaceImage}`}
                    className="space-image"
                  />
                  <div className="separate-booking-container">
                    <div className="space-details">
                      <h3>{bookingDetail.SpaceName}</h3>
                      <p>
                        {" "}
                        {Number(bookingDetail.SpaceSeatCount) === 1
                          ? `${bookingDetail.SpaceSeatCount} Seat`
                          : `${bookingDetail.SpaceSeatCount} Seats`}
                      </p>
                    </div>
                    <div
                      className="custom-status-badge"
                      style={{
                        backgroundColor: bookingDetail.StatusColor,
                        color: bookingDetail.StatusTextColor,
                      }}
                    >
                      {bookingDetail.Status}
                    </div>
                  </div>
                  <div style={{ height: "100px" }}></div>
                </div>
                <hr
                  style={{
                    borderTop: "1px solid lightgrey",
                    width: "100%",
                  }}
                ></hr>
                <div>
                  <div className="space-info">
                    <div className="info-item">
                      <div className="ul-list">
                        <li>
                          <h4>Booking ID</h4>
                        </li>
                        <li>
                          <div className="ul-list-view">
                            {bookingDetail.BookingId}
                          </div>
                        </li>
                      </div>
                      <div
                        onClick={() => {
                          window.setTimeout(() => {
                            window.scroll({
                              top: 950,
                              left: 0,
                              behavior: "smooth",
                            });
                          }, 5);
                        }}
                      >
                        {/* <span
                        className="k-icon k-font-icon k-i-edit edit-link"
                        style={{ paddingRight: " 15px" }}
                      ></span>
                      <span
                        className="edit-link"
                        style={{ textDecoration: "underline" }}
                        onClick={() => {
                          window.setTimeout(() => {
                            window.scroll({
                              top: 950,
                              left: 0,
                              behavior: "smooth",
                            });
                          }, 5);
                        }}
                      >
                        EDIT
                      </span> */}
                      </div>
                    </div>
                    <div className="info-item">
                      <div className="ul-list">
                        <li>
                          <h4>Dates</h4>
                        </li>
                        <li>
                          <div className="ul-list-view">
                            {convertDate(bookingDetail.BookingFrom)} -{" "}
                            {convertDate(bookingDetail.BookingTo)}
                          </div>
                          {/* <div> - {convertDate(bookingDetail.BookingTo)}</div> */}
                        </li>
                        {/* {selectedDurationName === "Hourly" && (
                              <li>
                                <div>
                                  {headerFormatDate(new Date(startDate))}
                                </div>
                              </li>
                            )}
                            {selectedDurationName !== "Hourly" && (
                              <li>
                                <div>
                                  {startDate} - {endDate}
                                </div>
                              </li>
                            )} */}
                      </div>
                      <div
                        onClick={() => {
                          window.setTimeout(() => {
                            window.scroll({
                              top: 950,
                              left: 0,
                              behavior: "smooth",
                            });
                          }, 5);
                        }}
                      >
                        {/* <span
                        className="k-icon k-font-icon k-i-edit edit-link"
                        style={{ paddingRight: " 15px" }}
                      ></span>
                      <span
                        className="edit-link"
                        style={{ textDecoration: "underline" }}
                        onClick={() => {
                          window.setTimeout(() => {
                            window.scroll({
                              top: 950,
                              left: 0,
                              behavior: "smooth",
                            });
                          }, 5);
                        }}
                      >
                        EDIT
                      </span> */}
                      </div>
                    </div>
                    {bookingDetail.DurationName !== "" && (
                      <div className="info-item">
                        <div className="ul-list">
                          <li>
                            <h4>Duration</h4>
                          </li>
                          <li>
                            <div className="ul-list-view">
                              {bookingDetail.DurationName}{" "}
                              {bookingDetail.NoOfMonths !== 0 &&
                                `* ${bookingDetail.NoOfMonths}`}
                            </div>
                          </li>

                          {/* {selectedDurationName === "Monthly" && (
                              <li>
                                <div>
                                  {selectedDurationName} * {selectedMonthCount}
                                </div>
                              </li>
                            )}
                            <li>
                              {selectedDurationName !== "Monthly" && (
                                <div>{selectedDurationName}</div>
                              )}
                            </li> */}
                        </div>
                        <div
                          onClick={() => {
                            window.setTimeout(() => {
                              window.scroll({
                                top: 950,
                                left: 0,
                                behavior: "smooth",
                              });
                            }, 5);
                          }}
                        >
                          {/* <span
                        className="k-icon k-font-icon k-i-edit edit-link"
                        style={{ paddingRight: " 15px" }}
                      ></span>
                      <span
                        className="edit-link"
                        style={{ textDecoration: "underline" }}
                      >
                        EDIT
                      </span> */}
                        </div>
                      </div>
                    )}

                    {bookingDetail.BookingStatus === 2 && (
                      <Card>
                        <div>
                          <Label className="cancel-heading">
                            Cancellation and Refunds Policy
                          </Label>
                        </div>
                        <div className="total-color">
                          <ul>
                            <p className="policy_title">
                              1. Cancellation by Members:{" "}
                            </p>
                            <p>
                              You may cancel your membership at any time by
                              providing written notice to us via email or
                              through your account on the Site. Your membership
                              will be terminated at the end of your current
                              billing cycle, and you will not be charged for
                              subsequent billing cycles.
                            </p>
                            <p className="policy_title">2. Refund Policy: </p>
                            <p>
                              If you cancel any of your membership for any of
                              our spaces, all refunds will be processed within 7
                              working days after receipt of all documentation.
                            </p>
                            <p className="policy_title">
                              3. No Refunds for Services Rendered
                            </p>
                            <p>
                              We do not provide refunds for services that have
                              already been rendered. Any partial use of a
                              membership plan will be billed in full, and no
                              prorated refunds will be provided.
                            </p>
                            <p className="policy_title">
                              4. Cancellation by Us
                            </p>
                            <p>
                              We reserve the right to cancel your membership or
                              access to our services at any time if you violate
                              these Terms. In such cases, no refund will be
                              provided.{" "}
                            </p>
                          </ul>
                        </div>

                        <div className="button-view">
                          <Button
                            className="button-style-cancel"
                            onClick={(e) => setVisibleDialog(true)}
                          >
                            Request to Cancel
                          </Button>
                        </div>
                      </Card>
                    )}
                    <div className="spacing-card"></div>
                    {/* <Card>
                    <div style={{ padding: "20px" }}>
                      <h3 className="h5">Change History</h3>
                      <div className="ul-list">No changes yet</div>
                    </div>
                  </Card> */}
                    {/* {selectedDurationName === "Hourly" &&
                        startTimeDilog !== "" ? (
                          <div className="info-item">
                            <div className="ul-list">
                              <li>
                                <h4>Start Time</h4>
                              </li>
                              <li>
                                <div>
                                  {headerFormatDate(new Date(startTimeDilog))}
                                </div>
                              </li>
                            </div>
                            <div
                              onClick={() => {
                                // getHourlyListDetails(checkInDate);
                                setPickDuration(true);
                              }}
                            >
                              <span
                                className="k-icon k-font-icon k-i-edit edit-link"
                                style={{ paddingRight: " 15px" }}
                              ></span>

                              <span
                                className="edit-link"
                                style={{ textDecoration: "underline" }}
                                onClick={() => {
                                  // getHourlyListDetails(checkInDate);
                                  setPickDuration(true);
                                }}
                              >
                                EDIT
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {selectedDurationName === "Hourly" &&
                        endTimeDilog !== "" ? (
                          <div className="info-item">
                            <div className="ul-list">
                              <li>
                                <h4>End Time</h4>
                              </li>
                              <li>
                                <div>
                                  {headerFormatDate(new Date(endTimeDilog))}
                                </div>
                              </li>
                            </div>
                            <div
                              onClick={() => {
                                // getHourlyListDetails(checkInDate);
                                setPickDuration(true);
                              }}
                            >
                              <span
                                className="k-icon k-font-icon k-i-edit edit-link"
                                style={{ paddingRight: " 15px" }}
                              ></span>

                              <span
                                className="edit-link"
                                style={{ textDecoration: "underline" }}
                                onClick={() => {
                                  // getHourlyListDetails(checkInDate);
                                  setPickDuration(true);
                                }}
                              >
                                EDIT
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}

                        {/* {selectedSeatsCount !== defaultSeatCountValue && ( */}
                    {/* {isCheckSpaceCount === false && (
                          <div className="info-item">
                            <div className="ul-list">
                              <li>
                                <h4>Seats</h4>
                              </li>
                              <li>
                                <div>{selectedSeatsCount} Seats</div>
                              </li>
                            </div>
                            <div onClick={handleEdit}>
                              <span
                                className="k-icon k-font-icon k-i-edit edit-link"
                                style={{ paddingRight: " 15px" }}
                              ></span>
                              <span
                                className="edit-link"
                                style={{ textDecoration: "underline" }}
                              >
                                EDIT
                              </span>
                            </div>
                          </div>
                        )} */}
                  </div>
                </div>
              </div>
              {/* Right container */}
              <Card className="booking-right-section">
                {/* <div className="booking-details-container"> */}
                {/* {bookingDetailsData.map((bookingDataList: any) => ( */}
                <div className="date-section">
                  <div className="date-box">
                    <Label style={{ color: "#757575" }}>Check-in Date</Label>
                    <div className="date-box-value">
                      <Label className="custom-value">
                        {convertDate(bookingDetail.BookingFrom)}
                      </Label>
                    </div>
                  </div>
                  <div className="date-box">
                    <Label style={{ color: "#757575" }}>Check-out Date</Label>
                    <div className="edit-date">
                      <div className="date-box-value">
                        <Label className="custom-value">
                          {convertDate(bookingDetail.BookingTo)}
                        </Label>
                      </div>
                    </div>
                  </div>
                  <div className="date-box">
                    {/* <span
                    className="k-icon k-font-icon k-i-edit edit-link"
                    style={{ paddingRight: " 15px" }}
                  >
                    <span
                      className="edit-link"
                      style={{ textDecoration: "underline" }}
                    >
                      EDIT
                    </span>
                  </span> */}
                  </div>
                </div>
                {/* <div className="price-section">
                <p className="price-label">Price</p>
                <p className="price-value">₹ 9280</p>
              </div> */}
                {/* ))} */}
                {/* </div> */}

                <div className="bill-details">
                  <div className="my-booking-container">
                    {/* <div className="summary-item">
                  <span className="summary-text">Payment History</span>
                  <div>
                    <span className="field-text partiallyPaid">
                      Partially Paid
                    </span>
                  </div>
                </div> */}

                    <div className="summary-dotted-line" />
                    <div className="summary-item">
                      <span className="field-label total-color">Deposit</span>
                      <span className="field-text">
                        ₹ {Math.round(bookingDetail.Deposit)}
                      </span>
                      {/* <span className="field-text">Cash</span> */}
                    </div>
                    <div className="summary-dotted-line" />
                    <div className="summary-item">
                      <span className="field-label total-color">
                        Space Amount
                        {/* Payment on {convertDate(bookingDetail.BookingDatetime)} */}
                      </span>
                      <span className="field-text">
                        ₹{" "}
                        {Math.round(
                          bookingDetail.TotalAmount - bookingDetail.Deposit
                        )}
                      </span>
                    </div>
                    <div className="summary-dotted-line" />
                    <div className="summary-item total">
                      <span className="field-label-total-color-price">
                        Total Price
                      </span>
                      <span className="summary-price">
                        ₹ {Math.round(bookingDetail.TotalAmount)}
                      </span>
                    </div>
                  </div>
                  {invoicePath !== "" && (
                    <div className="button-view-row">
                      <Button
                        className="button-style-outline-2"
                        onClick={(e) => handleDownloadInvoice()}
                      >
                        DOWNLOAD INVOICE
                      </Button>
                      <Button
                        className="button-style-outline-1"
                        onClick={(e) => handleViewInvoice()}
                      >
                        VIEW INVOICE
                      </Button>
                    </div>
                  )}
                </div>
              </Card>
              <div></div>
            </div>
          </div>
        </div>
      )}
      {/* Cancel dialog */}
      <div>
        {visibleDialog && (
          <Dialog
            onClose={() => setVisibleDialog(false)}
            className="dialog-container"
          >
            <div className="dialog-content">
              <div className="header-container">
                <Label className="dialog-heading">Request Cancellation</Label>
              </div>
              <div className="input-group">
                <Label className="field-label">Notes</Label>
                <Label className="manditory-fields">*</Label>
                <TextArea
                  placeholder="Enter Note"
                  name="notes"
                  autoComplete="off"
                  defaultValue={notes}
                  onChange={(e) => setNotes(e.value)}
                  required={
                    notes?.trim() === "" && true && isCheckNotesErr === true
                  }
                  rows={4}
                />
                {notesErr === "" ||
                  (isCheckNotesErr === true && (
                    <Label className="error-field">{notesErr}</Label>
                  ))}
              </div>
              <Button
                type={"submit"}
                className="login-button-style"
                onClick={cancelAPIRequest}
              >
                Request to Cancel
              </Button>
              <div
                className="link-register"
                style={{ color: "#ED823A" }}
                onClick={() => {
                  setVisibleDialog(false);
                }}
              >
                Close
              </div>
            </div>
          </Dialog>
        )}
      </div>
      {/* Common dialog */}
      {visible && (
        <Dialog
          title={"Message"}
          onClose={toggleDialog}
          style={{ alignItems: "center" }}
        >
          <Label
            style={{
              height: "80px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {alertContent}
            <div
              style={{
                padding: "20px",
              }}
            />
            <Button
              className="alert-button"
              type="button"
              onClick={toggleDialog}
            >
              Ok
            </Button>
          </Label>
        </Dialog>
      )}
    </div>
  );
};
