import React, { useEffect, useRef } from "react";
import { FieldProps } from "@progress/kendo-react-form";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { Label } from "@progress/kendo-react-labels";

const getHoursOnly = (dateString: any) => {
  const dateObject = new Date(dateString);
  const hours = dateObject.getHours();
  return hours.toString().padStart(2, "");
};

const getHoursAndMinutes = (dateString: any) => {
  const dateObject = new Date(dateString);
  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

const addHour = (time: any, hour: any) => {
  const newTime = new Date(time); // Copy the original time
  newTime.setHours(newTime.getHours() + hour); // Add 1 hour
  return newTime; // Update state with new time
};

// Time limits
const TIME_LIMIT_START = new Date();
const TIME_LIMIT_START_END = new Date();
let TIME_LIMIT_END = new Date();
let isDisableEndDate = false;

let startTimeValue: any;
let startEndTimeValue: any;

// CSS styling for the components
const styles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}

.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}

.no-wheel input::-moz-inner-spin-button {
  display: none;
}
`;

// Start Time Component
export const StartTime = (fieldRenderProps: FieldProps) => {
  const { value, onChange, form } = fieldRenderProps;
  TIME_LIMIT_START.setHours(Number(getHoursOnly(value)), 0, 0);
  TIME_LIMIT_START_END.setHours(Number(getHoursOnly(value)) + 1, 0, 0);
  if (Number(localStorage.getItem("variant_number")) !== 0) {
    const [hours, minutes] =
      localStorage.getItem("hourly_close")?.split(":") || [];
    if (
      Number(getHoursOnly(value)) +
        Number(localStorage.getItem("variant_number")) <=
      Number(hours)
    ) {
      if (
        getHoursAndMinutes(
          addHour(value, Number(localStorage.getItem("variant_number")))
        ) <=
        getHoursAndMinutes(
          TIME_LIMIT_END.setHours(Number(hours), Number(minutes), 0)
        )
      ) {
        TIME_LIMIT_END = addHour(
          value,
          Number(localStorage.getItem("variant_number"))
        );
      } else {
        const [hours, minutes] = localStorage
          .getItem("hourly_close")!
          .split(":");
        TIME_LIMIT_END.setHours(Number(hours), Number(minutes), 0);
      }
    } else {
      const [hours, minutes] =
        localStorage.getItem("hourly_close")?.split(":") || [];
      TIME_LIMIT_END.setHours(Number(hours), Number(minutes), 0);
    }
  } else {
    const [hours, minutes] =
      localStorage.getItem("hourly_close")?.split(":") || [];
    TIME_LIMIT_END.setHours(Number(hours), Number(minutes), 0);
  }
  startTimeValue = value;
  startEndTimeValue = addHour(value, 1);
  if (
    getHoursAndMinutes(startEndTimeValue) <= getHoursAndMinutes(TIME_LIMIT_END)
  ) {
    isDisableEndDate = false;
  } else {
    isDisableEndDate = true;
  }
  const handleStartTimeChange = (event: any) => {
    const startTime = event.value;
    TIME_LIMIT_START_END.setHours(event.value.getHours() + 1, 0, 0);
    if (onChange) {
      onChange(event);
    }
    if (startTime && form) {
      const endTime = new Date(startTime);
      endTime.setHours(startTime.getHours() + 1); // Add 1 hour
      if (endTime <= TIME_LIMIT_END) {
        form.setFieldValue?.("End", endTime);
      }
    }
  };

  // Reference for the TimePicker input element
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  // Prevent mouse wheel scrolling
  useEffect(() => {
    const element = timePickerRef.current;
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
    };
    if (element) {
      element.addEventListener("wheel", handleWheel, { passive: false });
      return () => {
        element.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  return (
    <>
      <style>{styles}</style>
      <div className="no-wheel" ref={timePickerRef}>
        <TimePicker
          {...fieldRenderProps}
          value={startTimeValue}
          onChange={handleStartTimeChange}
          min={TIME_LIMIT_START}
          max={TIME_LIMIT_END}
          format="t"
          steps={{ hour: 1, minute: 15 }}
          width="300px"
          disabled
        />
      </div>
    </>
  );
};

// End Time Component
export const EndTime = (fieldRenderProps: FieldProps) => {
  const { value, onChange, form } = fieldRenderProps;
  const startTime = form?.getFieldValue("Start");

  const handleEndTimeChange = (event: any) => {
    const selectedEndTime = event.value;
    if (
      event.nativeEvent.code === "ArrowUp" ||
      event.nativeEvent.code === "ArrowDown" ||
      event.nativeEvent.type === "input"
    ) {
      event.syntheticEvent.preventDefault();
    } else {
      if (startTime) {
        const minEndTime = new Date(startTime);
        minEndTime.setHours(startTime.getHours() + 1);
        if (
          selectedEndTime >= minEndTime &&
          selectedEndTime <= TIME_LIMIT_END
        ) {
          onChange?.(event);
        }
      } else {
        onChange?.(event);
      }
    }
  };

  const timePickerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = timePickerRef.current;

    // Function to handle mouse wheel events
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault(); // Prevent scrolling with mouse wheel
    };

    // Function to handle keyboard events
    const handleKeyDown = (event: KeyboardEvent) => {
      event.preventDefault();
    };

    if (element) {
      // Attach wheel event listener to prevent scrolling with mouse wheel
      element.addEventListener("wheel", handleWheel, { passive: false });

      const inputElement = element.querySelector("input");

      if (inputElement) {
        // Attach keydown event listener to prevent up/down arrows and numbers
        inputElement.addEventListener("keydown", handleKeyDown);
      }

      // Cleanup event listeners when component unmounts
      return () => {
        element.removeEventListener("wheel", handleWheel);
        if (inputElement) {
          inputElement.removeEventListener("keydown", handleKeyDown);
        }
      };
    }
  }, []);

  return (
    <>
      <style>{styles}</style>
      <div className="no-wheel" ref={timePickerRef}>
        <TimePicker
          {...fieldRenderProps}
          value={value}
          disabled={isDisableEndDate}
          onChange={handleEndTimeChange}
          min={startEndTimeValue}
          max={TIME_LIMIT_END}
          format="t"
          steps={{ hour: 1, minute: 15 }}
          width="300px"
          ref={timePickerRef}
        />
        {isDisableEndDate === true && (
          <Label style={{ marginTop: "10px" }} className="manditory-fields">
            You can't book hourly slot. Please try another time
          </Label>
        )}
      </div>
    </>
  );
};
