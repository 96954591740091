import * as React from "react";

import { FormElement, Field } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import {
  DatePicker,
  DateTimePicker,
  TimePicker,
} from "@progress/kendo-react-dateinputs";
import { SchedulerFormEditorProps } from "@progress/kendo-react-scheduler";
import { Error } from "@progress/kendo-react-labels";
import { StartTime, EndTime } from "./StartTime";

export const CustomFormEditor = (props: SchedulerFormEditorProps) => {
  return (
    <FormElement horizontal={false}>
      <div className="k-form-field">
        <Label>Start Time</Label>
        <div className="k-form-field-wrap">
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Field
              name={"Start"}
              component={StartTime}
              as={DateTimePicker}
              rows={1}
              width={"300px"}
              format="t"
            />
          </div>
        </div>
      </div>
      <div className="k-form-field">
        <Label>End Time</Label>
        <div className="k-form-field-wrap">
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Field
              name={"End"}
              component={EndTime}
              as={DateTimePicker}
              rows={1}
              width={"300px"}
              format="t"
            />
          </div>
        </div>
      </div>
    </FormElement>
  );
};
