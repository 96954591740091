import { Button } from "@progress/kendo-react-buttons";
import { minusIcon, plusIcon, xIcon } from "@progress/kendo-svg-icons";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { commonBaseUrl } from "../services/CommonUtils";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Label } from "@progress/kendo-react-labels";

interface CartItem {
  id: number;
  name: string;
  typeId: string;
  originalPrice: number;
  fixedPrice: number;
  price: number;
  quantity: number;
  maxQty: number;
}

interface packageDetails {
  id: number;
  packageName: string;
  inclusion: string;
  exclusion: string;
  isActive: boolean;
  foodList: [
    {
      id: number;
      packageHeaderId: number;
      categoryId: number;
      subCategoryId: number;
      packageQty: number;
      availableQty: number;
      subCategory: {
        id: number;
        categoryId: number;
        name: string;
        isAvailable: boolean;
        price: number;
        typeId: number;
        fixedQuantity: number;
        quantity: number;
        jsonDate: string;
        description: string;
        isActive: boolean;
        isDelete: boolean;
        createdBy: number;
        createdOn: string;
        modifiedBy: number;
        modifiedOn: string;
        fromDate: string;
        toDate: string;
      };
    }
  ];
}

interface foodDetails {
  categoryId: number;
  subCategoryId: number;
  consumedQty: number;
  fixedQty: number;
  foodPrice: number;
}

const OrderSummary = ({
  cart,
  compliment,
  onUpdateCart,
}: {
  cart: CartItem[];
  compliment: foodDetails[];
  onUpdateCart: (
    updatedCart: CartItem[],
    updateCompliment: foodDetails[]
  ) => void;
}) => {
  const baseURL = commonBaseUrl();
  const hasFetched = useRef(false);

  const [visible, setVisible] = React.useState<boolean>(false);
  const [resetData, setResetData] = React.useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [alertContent, setAlertContent] = React.useState<String>("");
  const [packageList, setPackageList] = useState<packageDetails>();

  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    getPackageUtilization();
  });

  const getPackageUtilization = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/GetPackagecomplementryFoods?bookingId=${sessionStorage.getItem(
          "booking_id"
        )}`
      );
      setPackageList(response.data);
    } catch (err) {
      alert(err);
    }
  };

  const handleIncrease = (id: number) => {
    const existingItem = cart.find((cartItem) => cartItem.id === id);
    if (Number(existingItem?.quantity) < Number(existingItem?.maxQty)) {
      const updatedCart = cart.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      );

      if (compliment.length !== 0) {
        const existingItem = compliment.find(
          (cartItem) => cartItem.subCategoryId === id
        );
        if (existingItem === undefined) {
          console.log("undefined");
          let existFood = false;
          if (packageList && packageList.foodList) {
            for (let c = 0; c < updatedCart.length; c++) {
              for (let p = 0; p < packageList.foodList.length; p++) {
                if (
                  updatedCart[c].id === packageList.foodList[p].subCategory.id
                ) {
                  const packageMain = {
                    categoryId: packageList.foodList[p].categoryId,
                    subCategoryId: packageList.foodList[p].subCategory.id,
                    consumedQty: updatedCart[c].quantity,
                    fixedQty: packageList.foodList[p].availableQty,
                    foodPrice: updatedCart[c].price * updatedCart[c].quantity,
                  };
                  const existingItem = compliment.find(
                    (cartItem) =>
                      cartItem.subCategoryId === packageMain.subCategoryId
                  );
                  if (existingItem) {
                    break;
                  } else {
                    existFood = true;
                    compliment.push(packageMain);
                    break;
                  }
                }
              }
            }

            const updatedPriceCart = updatedCart.map((item) =>
              item.id === id
                ? {
                    ...item,
                    originalPrice:
                      item.originalPrice +
                      (existFood === true
                        ? item.originalPrice
                        : item.fixedPrice),
                  }
                : item
            );

            onUpdateCart(updatedPriceCart, compliment!);
          } else {
            console.error("Package list is undefined or empty", packageList);
          }
        } else {
          console.log("if exist");
          let existFood = false;
          if (packageList && packageList.foodList) {
            for (let c = 0; c < updatedCart.length; c++) {
              for (let p = 0; p < packageList.foodList.length; p++) {
                if (
                  updatedCart[c].id === packageList.foodList[p].subCategory.id
                ) {
                  for (let e = 0; e < compliment.length; e++) {
                    if (
                      compliment[e].subCategoryId === updatedCart[c].id &&
                      compliment[e].fixedQty >= updatedCart[c].quantity
                    ) {
                      if (
                        compliment[e].consumedQty !== updatedCart[c].quantity
                      ) {
                        compliment[e].consumedQty =
                          compliment[e].consumedQty + 1;
                        compliment[e].foodPrice =
                          Number(packageList.foodList[p].subCategory.price) *
                          Number(compliment[e].consumedQty);
                        existFood = true;
                        break;
                      }
                    }
                  }
                }
              }
            }

            const updatedPriceCart = updatedCart.map((item) =>
              item.id === id
                ? {
                    ...item,
                    originalPrice:
                      item.originalPrice +
                      (existFood === true
                        ? item.originalPrice
                        : item.fixedPrice),
                  }
                : item
            );

            onUpdateCart(updatedPriceCart, compliment!);
          } else {
            console.error("Package list is undefined or empty", packageList);
          }
        }
      } else {
        console.log("else exist");
        let existFood = false;
        if (packageList && packageList.foodList) {
          for (let c = 0; c < updatedCart.length; c++) {
            for (let p = 0; p < packageList.foodList.length; p++) {
              if (
                updatedCart[c].id === packageList.foodList[p].subCategory.id
              ) {
                const packageMain = {
                  categoryId: packageList.foodList[p].categoryId,
                  subCategoryId: packageList.foodList[p].subCategory.id,
                  consumedQty: updatedCart[c].quantity,
                  fixedQty: packageList.foodList[p].availableQty,
                  foodPrice: updatedCart[c].price,
                };
                existFood = true;
                compliment.push(packageMain);
                break;
              }
            }
          }

          const updatedPriceCart = updatedCart.map((item) =>
            item.id === id
              ? {
                  ...item,
                  originalPrice:
                    item.originalPrice +
                    (existFood === true ? item.originalPrice : item.fixedPrice),
                }
              : item
          );

          onUpdateCart(updatedPriceCart, compliment!);
        } else {
          console.error("Package list is undefined or empty", packageList);
        }
      }
    } else {
      setAlertContent(
        `Maximum quantity reached for ${existingItem?.name} item`
      );
      setVisible(true);
    }
  };

  const handleDecrease = (id: number) => {
    const updatedCart = cart.map((item) =>
      item.id === id && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    let removeFood = false;
    if (packageList && packageList.foodList) {
      for (let c = 0; c < updatedCart.length; c++) {
        for (let p = 0; p < packageList.foodList.length; p++) {
          if (updatedCart[c].id === packageList.foodList[p].subCategory.id) {
            for (let e = 0; e < compliment.length; e++) {
              if (
                compliment[e].subCategoryId === updatedCart[c].id &&
                compliment[e].fixedQty >= updatedCart[c].quantity
              ) {
                if (compliment[e].consumedQty !== updatedCart[c].quantity) {
                  compliment[e].consumedQty = compliment[e].consumedQty - 1;
                  compliment[e].foodPrice =
                    Number(packageList.foodList[p].subCategory.price) *
                    Number(compliment[e].consumedQty);
                  removeFood = true;
                  break;
                }
              }
            }
          }
        }
      }
    } else {
      console.error("Package list is undefined or empty", packageList);
    }
    const updatedPriceCart = updatedCart.map((item) =>
      item.id === id
        ? {
            ...item,
            originalPrice:
              item.originalPrice -
              (removeFood === true ? item.originalPrice : item.fixedPrice),
          }
        : item
    );

    onUpdateCart(updatedPriceCart, compliment!);
  };

  const handleRemove = (id: number) => {
    const updatedCart = cart.filter((item) => item.id !== id);
    const updateCompliment = compliment.filter(
      (item: any) => item.subCategoryId !== id
    );
    onUpdateCart(updatedCart, updateCompliment);
  };

  const subtotal = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const complimentorySubtotal = compliment.reduce(
    (total, item) => total + item.foodPrice,
    0
  );

  const gst = subtotal * 0;
  const total = subtotal + gst;

  const convertToISOFormat = (inputDate: any): string => {
    // Parse the input date string to a Date object
    const dateObj = new Date(inputDate);

    // Extract the individual date components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    // Get the timezone offset in hours and minutes
    const timezoneOffset = -dateObj.getTimezoneOffset(); // In minutes
    const offsetHours = String(Math.floor(timezoneOffset / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(
      2,
      "0"
    );

    // Construct the ISO 8601 formatted string with timezone
    const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+${offsetHours}:${offsetMinutes}`;

    return isoFormattedDate;
  };

  const handlePlaceOrder = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const bulkOrders: any[] = [];
    for (let c = 0; c < cart.length; c++) {
      const orderData = {
        foodOrderHeaderId: 0,
        subCategoryItemId: Number(cart[c].id),
        quantity: Number(cart[c].quantity),
        orderType: cart[c].typeId === "Veg" ? 1 : 2,
        price: Number(cart[c].originalPrice),
        isAvailable: true,
        isActive: true,
        isDelete: true,
        createdBy: 0,
        createdOn: convertToISOFormat(new Date()),
        modifiedBy: 0,
        modifiedOn: convertToISOFormat(new Date()),
      };
      bulkOrders.push(orderData);
    }

    const fullOrders = {
      userId: Number(localStorage.getItem("id")),
      orderDate: convertToISOFormat(new Date()),
      orderStatus: 1,
      totalAmount: Number(total - complimentorySubtotal),
      complementaryAmount: Number(complimentorySubtotal),
      bookingId: Number(sessionStorage.getItem("booking_id")),
      isActive: true,
      isDelete: true,
      createdBy: 0,
      createdOn: convertToISOFormat(new Date()),
      modifiedBy: 0,
      modifiedOn: convertToISOFormat(new Date()),
      foodOrderDetails: bulkOrders,
    };
    console.log("response", fullOrders);
    try {
      const response = await axios.post(
        `${baseURL}odata/FoodOrderHeaders`,
        fullOrders,
        {
          headers: header,
        }
      );
      if (response.status === 201) {
        console.log("order", response);
        if (compliment.length > 0) {
          complimentaryAPI(response.data.Id);
        } else {
          setResetData(true);
          setLoading(false);
          setVisible(true);
          setAlertContent("Your food order has been successfully booked");
        }
      }
    } catch (err: any) {
      setResetData(false);
      setVisible(true);
      setAlertContent(err.response.data.detail);
      setLoading(false);
    }
  };

  const complimentaryAPI = async (Id: any) => {
    const bulkOrders: any[] = [];
    for (let c = 0; c < compliment.length; c++) {
      const orderData = {
        categoryId: Number(compliment[c].categoryId),
        subCategoryId: Number(compliment[c].subCategoryId),
        consumedQty: Number(compliment[c].consumedQty),
      };
      bulkOrders.push(orderData);
    }
    const mainOrders = {
      bookingId: Number(sessionStorage.getItem("booking_id")),
      orderId: Number(Id),
      foodDetails: bulkOrders,
    };
    console.log("complimentaryAPI", mainOrders);
    try {
      const response = await axios.post(
        `${baseURL}odata/PackageUtilizations`,
        mainOrders,
        {
          headers: header,
        }
      );
      if (response.status === 201) {
        setResetData(true);
        setLoading(false);
        setVisible(true);
        setAlertContent("Your food order has been successfully booked");
      }
    } catch (err: any) {
      setResetData(false);
      setVisible(true);
      setAlertContent(err.response.data.detail);
      setLoading(false);
    }
  };

  const toggleDialog = () => {
    setVisible(!visible);
    if (resetData === true) {
      onUpdateCart([], []);
    }
  };

  return (
    <div>
      {loading && (
        <div className="loading-screen">
          <Loader size="large" type={"infinite-spinner"} />
        </div>
      )}
      {!loading && (
        <div className="order-summary">
          <h3>Order Summary</h3>
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item) => (
                <tr key={item.id}>
                  <td style={{ padding: "10px 0px 10px 0px;", width: "140px" }}>
                    {item.name}
                  </td>
                  <td style={{ padding: "10px 0px 10px 0px;", width: "100px" }}>
                    <Button
                      svgIcon={minusIcon}
                      className="order-icons icon-margin"
                      onClick={() => handleDecrease(item.id)}
                      disabled={item.quantity === 1 ? true : false}
                    ></Button>
                    {item.quantity}
                    <Button
                      className="order-icons icon-margin"
                      svgIcon={plusIcon}
                      disabled={item.quantity === item.maxQty ? true : false}
                      onClick={() => handleIncrease(item.id)}
                    ></Button>
                  </td>
                  <td style={{ padding: "10px 0px 10px 0px;", width: "100px" }}>
                    ₹ {item.price * item.quantity}
                  </td>
                  <td style={{ padding: "10px 0px 10px 0px;", width: "60px" }}>
                    <Button
                      className="order-remove-icon"
                      svgIcon={xIcon}
                      onClick={() => handleRemove(item.id)}
                    ></Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="total-order-summary">
            <div className="total-order-compliment-summary">
              <span className="field-text order-price order-bill">Total:</span>
              <span className="field-text order-price order-bill">
                ₹ {total.toFixed(2)}
              </span>
            </div>
            {compliment.length > 0 && (
              <div>
                <div className="total-order-compliment-summary">
                  <span className="order-bill">Complimentary discount:</span>
                  <span className="order-bill">
                    ₹ -{complimentorySubtotal.toFixed(2)}
                  </span>
                </div>
                <div className="total-order-compliment-summary">
                  <span className=" order-price">Grant Total:</span>
                  <span className="field-text order-price order-bill">
                    ₹{" "}
                    {(Number(total) - Number(complimentorySubtotal)).toFixed(2)}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="summary-dotted-line" />
          <Button className="button-style" onClick={handlePlaceOrder}>
            Order Now
          </Button>
          {visible && (
            <Dialog
              title={"Message"}
              onClose={toggleDialog}
              style={{ alignItems: "center" }}
            >
              <Label
                style={{
                  height: "80px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {alertContent}
                <div
                  style={{
                    padding: "20px",
                  }}
                />
                <Button
                  className="alert-button"
                  type="button"
                  onClick={toggleDialog}
                >
                  Ok
                </Button>
              </Label>
            </Dialog>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
